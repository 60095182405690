<template>
  <div v-if="consultation">
    <div class="bg-danger" id="printing">
      <div class="container">
        <div class="p-3 position-relative d-block">
          <div class="text-center">
            <h4 class="text-white mb-0 ff-title fw-strong">Consultation</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="container custom-container">
      <div
        class="
          position-relative
          d-flex
          justify-content-center
          align-items-center
          p-3
        "
      >
        <div class="card custom-card overflow-hidden">
          <!-- top -->
          <div class="card-body p-0" id="printing">
            <div class="row justify-content-end no-gutters">
              <div
                v-if="doctor"
                class=""
                :class="
                  activeBtn == 'prescriptions'
                    ? 'col col-md-8 col-sm-12'
                    : 'col-12'
                "
              >
                <div
                  class="
                    p-3
                    d-flex
                    justify-content-between
                    align-items-center
                    container-top-profile
                    mb-3
                  "
                >
                  <div
                    class="
                      img-container
                      custom-radius
                      overflow-hidden
                      mr-3
                      mb-2
                    "
                  >
                    <img
                      :src="getImageLink(doctor.profile_img)"
                      style="height: 100%; object-fit: cover"
                    />
                  </div>
                  <div
                    class="position-absolute dot rounded-circle"
                    :class="doctor.is_online ? 'bg-success' : 'bg-danger'"
                  ></div>
                  <div style="width: calc(100% - 60px)">
                    <h6 class="m-0 ff-title fw-strong">{{ doctor.name }}</h6>
                    <p class="small mb-0 text-muted">
                      <span class="pr-2">{{ doctor.specialist }}</span
                      ><span
                        class="border-left pl-2"
                        v-if="clinicData != null"
                        >{{ clinicData.org_name }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div
                :class="
                  activeBtn == 'prescriptions'
                    ? 'col col-md-4 col-sm-12'
                    : 'd-none'
                "
              >
                <div class="d-flex justify-content-end p-3" id="printing">
                  <button
                    v-if="activeBtn == 'prescriptions' && isPaid"
                    type="button"
                    class="btn btn-sm btn-light mr-2 custom-radius"
                    @click="handlePrint"
                  >
                    <i class="ri-printer-line align-middle mr-2"></i>Print
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- card chat -->
          <!-- navigation -->
          <div v-if="!pendingPayment && !isLoadingCircle"
            class="card-body p-0" id="printing">
            <ul class="nav nav-tabs-custom">
              <li
                :class="
                  activeBtn == 'chat'
                    ? 'nav-item w-50 text-center active'
                    : 'nav-item w-50 text-center '
                "
                @click="activeBtn = 'chat'"
              >
                Chat
              </li>
              <li
                :class="
                  activeBtn == 'prescriptions'
                    ? 'nav-item w-50 text-center active'
                    : 'nav-item w-50 text-center '
                "
                @click="activeBtn = 'prescriptions'"
              >
                <!-- Prescription -->
                Checkout
              </li>
            </ul>
          </div>
          <!-- loading -->
          <div v-if="isLoadingCircle" class="d-flex justify-content-center pb-4">
            Preparing consultation data...
          </div>
          <!-- content chat-->
          <div class="card-body p-0" v-if="activeBtn == 'chat' && !isLoadingCircle" id="printing">
            <div id="chat-container" class="chat-container p-3">
              <div v-for="message in messages" v-if="message && message.user" :key="message.id">
                <div
                  v-if="message.user.id != currentCustomer.user.id"
                  class="d-flex bubble-left position-relative"
                >
                  <div
                    class="position-absolute dot-sm rounded-circle"
                    :class="doctor.is_online ? 'bg-success' : 'bg-danger'"
                  ></div>
                  <div class="img-thumbnails custom-radius overflow-hidden">
                    <img
                      :src="getImageLink(doctor.profile_img)"
                      style="height: 100%; object-fit: cover"
                    />
                  </div>
                  <div style="max-width: calc(100% - 35px)" class="pl-2">
                    <div
                      class="d-flex justify-content-end align-items-center mb-2"
                    >
                      <p class="mb-0 text-light-muted small">
                        {{ getDatetimeFormat(message.sent_datetime) }}
                      </p>
                    </div>
                    <div
                      class="
                        bubble
                        bg-light
                        py-2
                        px-3
                        custom-radius
                        overflow-hidden
                      "
                    >
                      <p class="mb-0">{{ message.text }}</p>
                      <div v-if="message.file">
                        <img
                          v-if="
                            message.file.ext.includes('png') ||
                            message.file.ext.includes('jpg') ||
                            message.file.ext.includes('jpeg')
                          "
                          :src="getImageLink(message.file)"
                          width="100"
                          data-toggle="modal"
                          data-target="#view-image-modal"
                          @click="selectedImage = message.file"
                        />

                        <div
                          v-if="
                            message.file.ext.includes('.pdf') ||
                            message.file.ext.includes('.doc') ||
                            message.file.ext.includes('.docx') ||
                            message.file.ext.includes('.xlsx') ||
                            message.file.ext.includes('.ppt') ||
                            message.file.ext.includes('.csv') ||
                            message.file.ext.includes('.json') ||
                            message.file.ext.includes('.text')
                          "
                        >
                          <div
                            v-if="message.file.ext.includes('.xlsx')"
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="fas fa-file-excel fa-2x align-middle"
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0">{{ message.file.name }}</p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else-if="message.file.ext.includes('.pdf')"
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i class="fas fa-file-pdf fa-2x align-middle"></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0">{{ message.file.name }}</p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else-if="
                              message.file.ext.includes('.doc') ||
                              message.file.ext.includes('.docx')
                            "
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="fas fa-file-word fa-2x align-middle"
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0">{{ message.file.name }}</p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else-if="message.file.ext.includes('json')"
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="fas fa-file-code fa-2x align-middle"
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0">{{ message.file.name }}</p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else-if="message.file.ext.includes('.ppt')"
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="
                                  fas
                                  fa-file-powerpoint fa-2x
                                  align-middle
                                "
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0">{{ message.file.name }}</p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i class="fas fa-file fa-2x align-middle"></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0">{{ message.file.name }}</p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="d-flex bubble-right">
                  <div style="max-width: calc(100% - 35px)" class="pr-2">
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        mb-2
                      "
                    >
                      <!-- <h6 class="m-0 small-title">You</h6> -->
                      <p class="m-0 text-light-muted small">
                        {{ getDatetimeFormat(message.sent_datetime) }}
                      </p>
                    </div>
                    <div
                      class="
                        bubble
                        bg-light
                        py-2
                        px-3
                        custom-radius
                        overflow-hidden
                      "
                    >
                      <p class="mb-0">{{ message.text }}</p>
                      <div v-if="message.file">
                        <img
                          v-if="
                            message.file.ext.includes('png') ||
                            message.file.ext.includes('jpg') ||
                            message.file.ext.includes('jpeg')
                          "
                          :src="getImageLink(message.file)"
                          width="100"
                          data-toggle="modal"
                          data-target="#view-image-modal"
                          @click="selectedImage = message.file"
                        />

                        <div
                          v-if="
                            message.file.ext.includes('.pdf') ||
                            message.file.ext.includes('.doc') ||
                            message.file.ext.includes('.docx') ||
                            message.file.ext.includes('.xlsx') ||
                            message.file.ext.includes('.ppt') ||
                            message.file.ext.includes('.csv') ||
                            message.file.ext.includes('.json') ||
                            message.file.ext.includes('.text')
                          "
                        >
                          <div
                            v-if="message.file.ext.includes('.xlsx')"
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="
                                  fas
                                  fa-file-excel fa-2x
                                  align-middle
                                  text-white
                                "
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0 text-white">
                                {{ message.file.name }}
                              </p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else-if="message.file.ext.includes('.pdf')"
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="
                                  fas
                                  fa-file-pdf fa-2x
                                  align-middle
                                  text-white
                                "
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0 text-white">
                                {{ message.file.name }}
                              </p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else-if="
                              message.file.ext.includes('.doc') ||
                              message.file.ext.includes('.docx')
                            "
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="
                                  fas
                                  fa-file-word fa-2x
                                  align-middle
                                  text-white
                                "
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0 text-white">
                                {{ message.file.name }}
                              </p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else-if="message.file.ext.includes('json')"
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="
                                  fas
                                  fa-file-code fa-2x
                                  align-middle
                                  text-white
                                "
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0 text-white">
                                {{ message.file.name }}
                              </p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else-if="message.file.ext.includes('.ppt')"
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="
                                  fas
                                  fa-file-powerpoint fa-2x
                                  align-middle
                                  text-white
                                "
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0 text-white">
                                {{ message.file.name }}
                              </p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                          <div
                            v-else
                            class="
                              text-dark
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="mr-3">
                              <i
                                class="
                                  fas
                                  fa-file fa-2x
                                  align-middle
                                  text-white
                                "
                              ></i>
                            </div>
                            <div class="pl-3 border-left">
                              <p class="mb-0 text-white">
                                {{ message.file.name }}
                              </p>
                              <a
                                class="text-info small"
                                style="
                                  cursor: pointer;
                                  color: #307dff !important;
                                "
                                @click="
                                  handleDownload(
                                    config.API_URL + message.file.url,
                                    message.file.name
                                  )
                                "
                                >Download</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="img-thumbnails custom-radius overflow-hidden">
                    <img
                      src="/img/person_placeholder_2.jpg"
                      style="height: 100%; object-fit: cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-body p-0 border-top"
            v-if="activeBtn == 'chat' && !isLoadingCircle"
            id="printing"
          >
            <div v-if="!isEndChat" class="d-flex justify-content-between align-items-center p-3">
              <textarea
                rows="1"
                v-model="textMessage"
                placeholder="message..."
                class="
                  form-control
                  w-100
                  mr-2
                  border-0
                  custom-input custom-border
                "
                @keyup.enter="submitTextMessage"
              />
              <input
                id="file-input"
                type="file"
                style="display: none"
                ref="fileInput"
                @change="submitFileMessage"
              />
              <button
                type="button"
                class="btn btn-danger mr-2 bg-attachment custom-border"
                @click="triggerFileInput()"
              >
                <i class="ri-attachment-line"></i>
              </button>
              <button
                type="button"
                class="btn btn-danger bg-submit custom-border"
                @click="submitTextMessage"
              >
                <i class="ri-send-plane-line"></i>
              </button>
            </div>
            <div v-else class="d-flex justify-content-center align-items-center p-3">
              <span class="text-muted">Chat has ended.</span>
            </div>
          </div>
          <!-- content prescriptions -->
          <div
            class="card-body padding-0"
            v-if="activeBtn == 'prescriptions' && selectedPrescription && !isLoadingCircle"
          >
            <div class="card" v-if="isPaid">
              <div class="card-body border-bottom">
                <div class="logo">
                  <img src="./../../../assets/selcare-logo.svg" />
                </div>
                <div class="d-flex justify-content-between content-address">
                  <div class="text-left">
                    <p class="small m-0 fw-lighter">
                      No. 01-02, Level 1, 14/6, Jalan Pembangunan, Seksyen 14,
                      40000 Shah Alam, Selangor
                    </p>
                  </div>
                  <div class="text-right w-100 small fw-lighter">
                    <i class="ri-phone-line align-middle mr-2"></i>
                    +603-5521 7000
                  </div>
                </div>
              </div>
              <div class="card-body text-left">
                <div class="row mb-3">
                  <div class="col">
                    <h6 class="mb-0">
                      {{
                        customerDataSource != null
                          ? helper.snakeToTitleCase(customerDataSource.username)
                          : ""
                      }}
                    </h6>
                    <p class="small fw-lighter mb-0">
                      {{
                        selectedPrescription != null &&
                        selectedPrescription.customer != undefined &&
                        selectedPrescription.customer.billing_address !=
                          undefined
                          ? selectedPrescription.customer.billing_address
                              .address1
                          : ""
                      }}
                    </p>
                    <p class="small fw-lighter mb-0">
                      {{
                        selectedPrescription != null &&
                        selectedPrescription.customer != undefined &&
                        selectedPrescription.customer.billing_address !=
                          undefined
                          ? selectedPrescription.customer.billing_address
                              .address2
                          : ""
                      }}
                    </p>
                    <p class="small fw-lighter mb-0">
                      {{
                        selectedPrescription != null &&
                        selectedPrescription.customer != undefined &&
                        selectedPrescription.customer.billing_address !=
                          undefined
                          ? `${selectedPrescription.customer.billing_address.zipcode},
                      ${selectedPrescription.customer.billing_address.province}`
                          : ""
                      }}
                    </p>
                    <p class="small fw-lighter mb-0">
                      {{
                        selectedPrescription != null &&
                        selectedPrescription.customer != undefined &&
                        selectedPrescription.customer.billing_address !=
                          undefined
                          ? `${selectedPrescription.customer.billing_address.city},
                      ${selectedPrescription.customer.billing_address.country}`
                          : ""
                      }}
                    </p>
                  </div>
                </div>
                <div class="d-flex w-100">
                  <p class="small fw-bolder mr-2 mb-0">Prescription ID:</p>
                  <p class="small fw-lighter mb-0">
                    {{
                      selectedPrescription != null
                        ? selectedPrescription.prescription_name
                        : ""
                    }}
                  </p>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="d-flex w-100">
                      <p class="small fw-bolder mr-2 mb-0">Issued On:</p>
                      <p class="small fw-lighter mb-0">
                        {{ getDate(selectedPrescription.issued_on) }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex w-100 justify-content-end">
                      <p class="small fw-bolder mr-2 mb-0">Expiry Date:</p>
                      <p class="small fw-lighter mb-0">
                        {{ getDate(selectedPrescription.expired_on) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body bg-light border-top">
                <div
                  v-if="
                    selectedPrescription.prescription_items &&
                    selectedPrescription.prescription_items.length > 0
                  "
                >
                  <!-- <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col" style="width: 10%">gm/ml</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in selectedPrescription.prescription_items" :key="item.id">
                        <td>{{ item.product.title }}</td>
                        <td>{{ item.quantity_limit }}</td>
                      </tr>
                    </tbody>
                  </table> -->
                  <table class="w-100 text-left">
                    <thead>
                      <th class="small-title fw-lighter pb-2 pr-2">
                        Medication
                      </th>
                      <th class="small-title fw-lighter pb-2 pr-2">Dosage</th>
                      <th class="small-title fw-lighter pb-2 pr-2">Route</th>
                      <th class="small-title fw-lighter pb-2 pr-2">
                        Frequency
                      </th>
                      <th class="small-title fw-lighter pb-2 pr-2">
                        Request amount
                      </th>
                      <th class="small-title fw-lighter pb-2 pr-2">Remarks</th>
                      <!-- <th class="small-title text-right fw-lighter pb-2">
                        Regimen
                      </th> -->
                    </thead>
                    <tbody
                      v-if="
                        selectedPrescription &&
                        selectedPrescription.prescription_items.length > 0
                      "
                    >
                      <tr
                        v-for="item in selectedPrescription.prescription_items"
                        :key="item.id"
                      >
                        <!-- <td class="small fw-bolder py-2">
                          {{
                            item.medicine && item.medicine.description
                              ? item.medicine.description
                              : ""
                          }}
                        </td> -->
                        <td
                          v-if="item.product"
                          class="small fw-bolder py-2 pr-2"
                        >
                          <!-- <router-link
                            :to="`/store/products/${item.product.id}`"
                            class="text-muted"
                            target="_blank"
                          > -->
                          {{
                            item.product && item.product.title
                              ? item.product.title
                              : "-"
                          }}
                          <!-- </router-link> -->
                          <!-- <small class="d-block" style="color: #49ad49"
                            >Available online. Add to cart.</small
                          > -->
                        </td>
                        <td
                          v-else-if="item.medicine"
                          class="small fw-bolder py-2 pr-2"
                        >
                          {{
                            item.medicine && item.medicine.description
                              ? item.medicine.description
                              : "-"
                          }}
                        </td>
                        <td class="small fw-bolder py-2">
                          {{ item.dosage ? item.dosage : "" }}
                        </td>
                        <td class="small fw-bolder py-2">
                          {{ item.route ? item.route : "" }}
                        </td>
                        <td class="small fw-bolder py-2">
                          {{ item.frequency ? item.frequency : "" }}
                        </td>
                        <td class="small fw-bolder py-2">
                          {{ item.amount ? item.amount : "" }}
                        </td>
                        <td class="small fw-bolder py-2">
                          {{ item.remarks ? item.remarks : "" }}
                        </td>
                        <!-- <td class="small fw-bolder text-right py-2">
                          {{ item.regiment ? item.regiment : "" }}
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="card-body text-left"
                v-if="
                  selectedPrescription.diagnosis &&
                  selectedPrescription.diagnosis.length > 0
                "
              >
                <p class="small mb-1">Diagnosis</p>
                <div
                  v-for="diagnosis in selectedPrescription.diagnosis"
                  :key="diagnosis.id"
                >
                  <p class="small-title mb-0">{{ diagnosis.icd }}</p>
                  <p class="text-muted small m-0">{{ diagnosis.remarks }}</p>
                </div>
              </div>
              <!-- <div class="card-body bg-light border-top">
                <div class="row">
                  <div class="col-md-7 col-sm-12">
                    <h6 class="small-title m-0">Issued By</h6>
                    <p class="m-0 small">
                      {{ selectedPrescription.doctor.name }}
                    </p>
                    <p class="m-0 small fw-ligther">Clinic ABC</p>
                  </div>
                  <div class="col-md-5 col-sm-12">
                    <h6 class="small-title m-0">Prescription ID :</h6>
                    <span class="small fw-ligther">{{
                      selectedPrescription.prescription_name
                    }}</span>
                  </div>
                </div>
              </div> -->
              <div class="card-body border-top text-left">
                <div class="row">
                  <div class="col-md-7 col-sm-7">
                    <p class="small fw-bolder">Issued By</p>
                    <h6 class="m-0">
                      {{
                        selectedPrescription != null &&
                        selectedPrescription.doctor
                          ? selectedPrescription.doctor.name
                          : ""
                      }}
                    </h6>
                    <p class="m-0 small fw-ligther">
                      {{
                        clinicData != null && clinicData.org_name != undefined
                          ? clinicData.org_name
                          : ""
                      }}
                    </p>
                    <p class="m-0 small fw-lighter">
                      {{
                        clinicData != null && clinicData.address != undefined
                          ? clinicData.address.address1
                          : ""
                      }}
                    </p>
                    <p class="m-0 small fw-lighter">
                      {{
                        clinicData != null && clinicData.address != undefined
                          ? clinicData.address.address2
                          : ""
                      }}
                    </p>
                    <p class="m-0 small fw-lighter">
                      {{
                        clinicData != null && clinicData.address != undefined
                          ? `${clinicData.address.zipcode}, ${clinicData.address.province}`
                          : ""
                      }}
                    </p>
                    <p class="m-0 small fw-lighter">
                      {{
                        clinicData != null && clinicData.address != undefined
                          ? `${clinicData.address.city}, ${clinicData.address.country}`
                          : ""
                      }}
                    </p>
                    <p
                      class="mb-0 mt-4 small fw-lighter"
                      v-if="
                        clinicData != null &&
                        clinicData.org_phone_num != undefined
                      "
                    >
                      <i class="ri-phone-line align-middle mr-2"></i>
                      {{ clinicData.org_phone_num }}
                    </p>
                  </div>
                  <div class="col-md-5 col-sm-5">
                    <!-- <div
                      class="
                        d-flex
                        justify-content-end
                        align-items-center
                        h-100
                      "
                    >
                      <qrcode-vue
                        :value="
                          selectedPrescription != null
                            ? selectedPrescription.prescription_name
                            : '123'
                        "
                        :size="150"
                      ></qrcode-vue>
                    </div> -->
                  </div>
                </div>
                <div class="row">
                  <!-- <div
                    class="
                      d-flex
                      justify-content-center
                      align-items-center
                      mt-4
                      w-100
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark mr-2"
                      style="border-color: #d0d5dd"
                    >
                      <div class="d-flex w-100 align-items-center">
                        <i
                          class="ri-shopping-cart-2-line align-middle mr-2"
                        ></i>
                        <p class="mb-0 align-middle">Checkout</p>
                      </div>
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark"
                      style="border-color: #d0d5dd"
                      @click="getLocation"
                      :disabled="isLoadingBtn"
                      id="printing"
                    >
                      <div
                        v-if="isLoadingBtn"
                        class="spinner-border text-secondary"
                        style="width: 1rem; height: 1rem"
                        role="status"
                      ></div>
                      <div class="d-flex w-100 align-items-center">
                        <i class="ri-map-pin-line align-middle mr-2"></i>
                        <p class="mb-0 align-middle">Find Nearby Clinic</p>
                      </div>
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="card border-0" v-else>
              <div class="card-body text-center">
                <div v-if="pendingPayment" class="mb-2">
                  <h4 class="mb-2 ff-title fw-strong">Consultation has ended.</h4>
                  <p class="mb-4 text-muted ff-desc">
                    Please be reminded that your consultation has ended, and payment for the consultation fee is still pending. To receive your prescription, kindly settle the fee through our online payment system.
                  </p>
                </div>
                <div v-else class="mb-2">
                  <h4 class="mb-2 ff-title fw-strong">Why wait?</h4>
                  <p class="mb-4 text-muted ff-desc">
                    You can access your prescriptions right here. Only after
                    making a payment may you access your prescription, medical
                    certificate, and referral letter, if any.
                  </p>
                </div>
                <div class="mb-2">
                  <p>
                    Only RM 15.00 for your expert
                    consultation
                  </p>
                  <!-- <p>
                    Only RM {{ consultationPrice.toFixed(2) }} for your expert
                    consultation
                  </p> -->
                </div>
                <button
                  class="
                    btn
                    mb-3
                    btn-primary
                    w-50
                    button-primary-color-bg
                    custom-radius
                  "
                  @click="handlePayment"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal center"
      tabindex="-1"
      :style="
        isShowModalVideoCall
          ? 'display: block; z-index:9999999; background-color: #4950579e;'
          : 'display: none'
      "
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="row no-gutters">
              <div class="col-md-6">
                <div
                  id="player-left"
                  class="
                    box-video
                    border-right border-secondary
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                ></div>
              </div>
              <div class="col-md-6">
                <div
                  id="player-right"
                  class="
                    box-video
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                ></div>
              </div>
            </div>

            <!-- <div class="footer w-100 text-center">
              <button
                type="button"
                class="btn btn-danger rounded-pill mr-2 text-white shadow-sm"
                data-bs-dismiss="modal"
                @click="isShowModalVideoCall = false"
              >
                <i class="ri-close-fill align-middle"></i>
              </button>
              <button
                type="button"
                class="btn btn-light shadow-sm rounded-pill mr-2"
                @click="toggleVideo"
              >
                <i v-if="isVideoOn" class="ri-camera-line align-middle"></i>
                <i v-else class="ri-camera-off-line align-middle"></i>
              </button>
              <button
                type="button"
                class="btn btn-light shadow-sm rounded-pill"
                @click="toggleAudio"
              >
                <i v-if="isAudioOn" class="ri-mic-2-line align-middle"></i>
                <i v-else class="ri-mic-off-line align-middle"></i>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewImageModal"
      aria-hidden="true"
      id="view-image-modal"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <img v-if="selectedImage" :src="getImageLink(selectedImage)" />
        </div>
      </div>
    </div>
    <div
      class="modal"
      tabindex="-1"
      :style="
        showModalConsultationsCompleted
          ? 'display: block; z-index:9999999; background-color: #4950579e;'
          : 'display: none'
      "
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center my-3">
            <h4 class="ff-title fw-strong">Thank you for choosing us!</h4>
            <p class="text-muted ff-desc mb-4">
              Your consultation
              <span
                v-if="consultation"
                class="text-uppercase fw-strong text-dark"
                >{{ `#C-` + consultation.id }}</span
              >
            </p>
            <!-- <p class="mb-3"></p> -->
            <!-- <p class="mb-3 text-muted">Your consultation has ended.</p> -->
            <!-- <p class="mb-3"> Thank you for supporting selcare.com</p> -->

            <button
              type="button"
              class="btn btn-danger button-primary-color-bg custom-radius"
              @click="viewPrescriptions"
            >
              View Prescription Details
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewModalCall"
      id="myModalCall"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="text-center mt-3">Incoming Video Call</h4>

            <div v-if="doctor" class="my-3">
              <div
                class="img-container custom-radius overflow-hidden m-auto"
                v-if="doctor.profile_img"
              >
                <img
                  :src="getImageLink(doctor.profile_img)"
                  style="height: 100%; object-fit: cover"
                />
              </div>
              <div class="text-center mt-2">
                <h6 class="m-0 fs-6">{{ doctor.name }}</h6>
                <p class="small text-muted" v-if="clinicData != null">
                  {{ clinicData.org_name }}
                </p>
              </div>
            </div>

            <div
              class="
                position-relative
                d-flex
                justify-content-between
                align-items-center
                mt-2
              "
            >
              <!-- <button
                type="button"
                class="btn btn-danger rounded-pill w-100 mr-2 icon-transform"
                @click="isShowModalVideoCall = false"
                data-dismiss="modal"
              >
                <i class="fas fa-phone fa-lg"></i>
              </button> -->
              <button
                type="button"
                class="btn btn-success rounded-pill w-100 icon-transform pickup"
                @click="() => {
                  isVideoCallReceieved = true
                  isShowModalVideoCall = true
                }"
              >
                <i class="fas fa-phone-volume fa-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "../../../../config";
import API from "./../../../utils/API";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Pusher from "pusher-js";
import moment from "moment";
import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios";
import Helper from "./../../../utils/helper";
import QrcodeVue from "qrcode.vue";
import io from 'socket.io-client'
import Swal from "sweetalert2";

export default {
  name: "Consultation",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      consultation: null,
      config: Config,
      isLoadingBtn: false,
      isLoadingCircle: true,
      activeBtn: "chat",
      doctor: null,
      chat: null,
      textMessage: null,
      clinicData: null,
      messages: [],
      selectedImage: null,
      prescriptions: [],
      selectedPrescription: null,
      isShowModalVideoCall: false,
      helper: Helper,
      customerDataSource: null,
      consultationIntervalStatus: null,
      showModalConsultationsCompleted: false,
      rtc: null,
      options: {
        appId: "37a8846c22a847138a9bd8547a30c2b1",
        channel: "selcare",
        token: null,
        uid: 1,
      },
      consultationPrice: 15.00, //hardcoded for now
      isPaid: false,
      isVideoOn: true,
      isAudioOn: true,
      pendingPayment: false,
      isEndChat: false,
      isVideoCallReceieved: false,
    };
  },
  computed: {
    ...mapGetters(["currentCustomer"]),
  },
  watch: {
    async isShowModalVideoCall() {
      $("#myModalCall").modal("hide");
      if (!this.isShowModalVideoCall) {
        if (this.rtc.localAudioTrack && this.rtc.localVideoTrack) {
          await this.rtc.localVideoTrack.stop(
            document.getElementById("player-left")
          );
          await this.rtc.localVideoTrack.setEnabled(false);
          await this.rtc.localAudioTrack.setMuted(true);
          await this.rtc.localVideoTrack.close();
          await this.rtc.localAudioTrack.close();
          await this.rtc.client.leave();

          await this.rtc.client.join(
            this.options.appId,
            this.options.channel,
            this.options.token,
            this.options.uid
          );
        }
        return;
      }
      // console.log("this.rtc", this.rtc);

      this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await this.rtc.client.publish([
        this.rtc.localAudioTrack,
        this.rtc.localVideoTrack,
      ]);
      this.rtc.localVideoTrack.play(document.getElementById("player-left"));
    },
    messages(){
      if(this.messages.length == 0) return;

      const findText = this.messages.find(x => x.text && x.text.includes("Thank you for choosing us. We are always there to care!"))
      if (findText) {
          this.isEndChat = true
      }
    },
    consultation(){
      //check if consultation is paid or not
      if(this.consultation && this.consultation.status == 'completed' && this.consultation.payment_status !== 'paid'){
        this.pendingPayment = true
        this.activeBtn = 'prescriptions'
      } else {
        this.pendingPayment = false
        this.activeBtn = 'chat'
      }

      //check if consultation is paid or not
      if (
        this.consultation.payment_status &&
        this.consultation.payment_status == "paid"
      )
        this.isPaid = true;
      else this.isPaid = false;
    }
  },
  methods: {
    ...mapActions([
      "setNewConsultation",
      "setActiveConsultation",
      "createConsultationPayment",
    ]),
    async toggleVideo() {
      if (this.rtc.localVideoTrack.isPlaying) {
        this.rtc.localVideoTrack.stop();
        this.rtc.localVideoTrack.stop(document.getElementById("player-left"));
        this.rtc.localVideoTrack.setEnabled(false);
      } else {
        this.rtc.localVideoTrack.setEnabled(true);
        this.rtc.localVideoTrack.play(document.getElementById("player-left"));
      }

      this.isVideoOn = !this.isVideoOn;
    },
    async toggleAudio() {
      if (this.isAudioOn === true) {
        this.rtc.localAudioTrack.setMuted(true);
      } else {
        this.rtc.localAudioTrack.setMuted(false);
      }

      this.isAudioOn = !this.isAudioOn;
    },
    goTo(url) {
      this.$router.push({
        path: url,
      });
    },
    getDate(date) {
      return date != null ? moment(date).format("DD MMM YYYY") : "-";
    },
    handlePrint() {
      window.print();
    },
    async getCustomers(data) {
      if (data.customer != undefined) {
        const res = await API.get(`/users/${data.customer.user}`);
        if (res.status == 200) {
          this.customerDataSource = res.data;
        }
      }
    },
    async initSocket(){
      const socket = io(API.getAPIUrl(), {
          auth: {
              'APP_KEY': process.env.VUE_APP_SOCKET_APP_KEY,
              'APP_SECRET': process.env.VUE_APP_SOCKET_APP_SECRET,
          }
      })

      socket.on('connect', (err) => {
          console.log(socket.id)
      })

      socket.on('connect_error', (err) => {
          console.log(err.message)
      })

      socket.on('consultation:update', async (data) => {
        if(data.id == this.consultation.id){
          console.log('match consultation id (socket)')

          if (data.status && data.status == "completed") {
            // this.showModalConsultationsCompleted = true;
            await this.setActiveConsultation({
              userID: this.currentCustomer.user.id,
            });
          }

          this.consultation = data;
        }
      })
    },
    async initAll() {
      if (!this.consultation) {
        this.$router.push("/e-prescription/choose-doctor");
        // await this.setNewConsultation({
        //   doctorID: '61fe187aa6263a7671f5fd33', //hardcoded first, nnti amik dari choose doctor page.
        //   // doctorID: '61feb96f3555cb264f04c091', //hardcoded first, nnti amik dari choose doctor page.
        // })
      }
      this.doctor = this.consultation.doctor;
      this.getClinic(this.consultation.doctor);
      // console.log(this.consultation.user);
      this.getCustomers(this.consultation);
      //get messages chat
      const res = await API.get(`/chats/${this.consultation.chat.id}`);
      this.chat = res.data;
      this.messages = this.chat.messages;
      this.prescriptions = this.consultation.prescriptions;
      const res02 = await API.get("/prescriptions/" + this.prescriptions[0].id);
      // console.log(res02);
      this.selectedPrescription = res02.data;
      // setInterval(async () => {
      //   //just for mocking data
      //   await this.refetchPrescription();
      // }, 5000);
    },
    // getConsutationStatus() {
    //   API.get(`/consultations/${this.consultation.id}`).then(async (res) => {
    //     // console.log("res", res.data);
    //     if (res.data && res.data.status && res.data.status == "completed") {
    //       // this.showModalConsultationsCompleted = true;
    //       await this.setActiveConsultation({
    //         userID: this.currentCustomer.user.id,
    //       });
    //       clearInterval(this.consultationIntervalStatus);
    //       this.consultationIntervalStatus = null;
    //     }
    //   });
    // },
    initPusher() {
      Pusher.logToConsole =
        process.env.VUE_APP_PUSHER_DEBUG === "true" ? true : false;
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: "ap1",
      });

      const channel = pusher.subscribe("chat-channel");
      let context = { title: "Pusher" };
      let handler = (_data) => {
        if(this.consultation.chat.id == _data.chatID){
          this.messages.push(_data.currentMessage);
          this.chatScrollBottom();
        }
      };
      channel.bind("my-event", handler, context);
    },
    async getClinic(data) {
      if (data) {
        const res = await API.get(
          `/corporate-organisations/${data.corporate_organisation}`
        );
        if (res.status == 200) this.clinicData = res.data;
      }
    },
    // async refetchPrescription() {
    //   //just for mocking data
    //   const res = await API.get("/prescriptions/" + this.prescriptions[0].id);
    //   this.selectedPrescription = res.data;
    // },
    handleCall() {
      var num = "tel:+60133257420";
      window.location.href = num;
    },
    getImageLink(_img) {
      if(!_img || !_img.url)
        return '/img/person_placeholder_2.jpg'
        
      return API.getAPIUrl() + _img.url;
    },
    getDatetimeFormat(_datetime) {
      return moment(_datetime).format("DD/MM/YYYY - h:mm A");
    },
    chatScrollBottom() {
      setTimeout(() => {
        let el = document.getElementById("chat-container");
        el.scrollTo({ top: el.scrollHeight, behavior: "smooth" });
      }, 250);
    },
    async submitTextMessage(_e) {
      //add new line if shift key pressed
      if(_e.shiftKey){
        this.textMessage += "\n";
        return;
      }

      //save chat message
      let messages2send = [];
      messages2send = messages2send.concat(this.messages);
      messages2send.push({
        user: this.currentCustomer.user,
        text: this.textMessage,
        // file: null,
        sent_datetime: moment(),
      });
      this.textMessage = null;

      try {
        const res = await API.put(`/chats/${this.consultation.chat.id}`, {
          messages: messages2send,
        });

        //call push chat message to trigger
        await API.post("/custom/push-chat-messages", {
          chatID: this.consultation.chat.id,
          currentMessage: res.data.messages[res.data.messages.length - 1],
        });

        this.chatScrollBottom();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: "<p class='mb-0'>There could be a problem with the network. Please reload the page.</p>",
          customClass: {
            popup: "swal2-default",
          },
          confirmButtonText: 'Reload'
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            //reload the page
            window.location.reload();
          }
        });
      }
    },
    getLocation() {
      this.isLoadingBtn = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
    },
    showPosition(position) {
      if (position) this.isLoadingBtn = false;
      window.open(
        `https://www.google.com.my/maps/dir/'${position.coords.latitude},${position.coords.longitude}'/${this.clinicData.org_name}`
      );
    },
    triggerFileInput() {
      document.getElementById("file-input").click();
    },
    async submitFileMessage(_event) {
      // console.log('e : ', _event)
      // console.log('e ref: ', this.$refs.fileInput)
      let formData = new FormData();
      formData.append("files", this.$refs.fileInput.files[0]);
      let resFileUpload = await API.post("/upload", formData);

      //save chat message
      let messages2send = [];
      messages2send = messages2send.concat(this.messages);
      messages2send.push({
        user: this.currentCustomer.user,
        // text: this.textMessage,
        file: resFileUpload.data[0].id,
        sent_datetime: moment(),
      });

      try {
        const res = await API.put(`/chats/${this.consultation.chat.id}`, {
          messages: messages2send,
        });

        //call push chat message to trigger
        await API.post("/custom/push-chat-messages", {
          chatID: this.consultation.chat.id,
          currentMessage: res.data.messages[res.data.messages.length - 1],
        });

        this.chatScrollBottom();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: "<p class='mb-0'>There could be a problem with the network. Please reload the page.</p>",
          customClass: {
            popup: "swal2-default",
          },
          confirmButtonText: 'Reload'
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            //reload the page
            window.location.reload();
          }
        });
      }
    },
    async handlePayment() {
      if(!this.consultation) {
        const response = await API.get(`/prescriptions/${this.$route.params.id}`);
        this.consultation = response.data;
      }

      let payloadData = {
        email: this.currentCustomer.user.email,
        phone: this.currentCustomer.user.phone,
        fullname: this.currentCustomer.user.username,
        description: `Consultation payment at ${moment().format(
          "dddd, D MMMM YYYY"
        )}`,
        testmode: true, // for now
        total_price: this.consultationPrice,
        total_tax: 0.0,
        consultation: this.consultation,
        transaction_type: "consultation",
      };
      this.createConsultationPayment(payloadData);
    },
    viewPrescriptions() {
      this.$router.push({
        path: `/e-prescription/check/${this.selectedPrescription.id}`,
      });
    },
    async handleDownload(url, name) {
      const image = await fetch(url);
      const imageBlog = await image.blob();
      const imageUrl = await URL.createObjectURL(imageBlog);

      const anchor = document.createElement("a");
      anchor.href = imageUrl;
      anchor.download = name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageUrl);
    },
  },

  async mounted() {
    this.isLoadingCircle = true;

    const response = await API.get(
      `/consultations?customer_id=${this.currentCustomer.user.customer.id}`
    );
    this.consultation = response.data[0];

    // //set agora uid as consultation id
    // this.options.uid = this.consultation.id;
    // set agora channel as consultation id
    this.options.channel = this.consultation.id;

    // console.log(this.consultation);
    // return
    if (this.consultation == null || this.consultation.status == "cancelled" || (this.consultation.status == "completed" && this.consultation.payment_status == "paid")) {
      return this.$router.push("/e-prescription/choose-doctor");
    } else {
      // this.consultationIntervalStatus = setInterval(() => {
      //   this.getConsutationStatus();
      // }, 1000);
      await this.initSocket();
      await this.initAll();
      await this.initPusher();
      // this.chatScrollBottom();

      const res = await axios.get(
        `https://agora-node-token-server-u7ed8.ondigitalocean.app/rtc/${this.options.channel}/publisher/uid/${this.options.uid}`
      );
      // console.log(res.data);
      this.options.token = res.data.rtcToken;

      this.rtc = {
        localAudioTrack: null,
        localVideoTrack: null,
        client: AgoraRTC.createClient({
          mode: "rtc",
          codec: "vp8",
        }),
      };

      if (this.rtc) {
        console.log('rtc options : ', this.options)
        await this.rtc.client.join(
          this.options.appId,
          this.options.channel,
          this.options.token,
          this.options.uid
        );
      }

      this.rtc.client.on("user-published", async (user, mediaType) => {
        // console.log('user AGORA: ', user)
        // console.log('user MEDIA TYPE: ', mediaType)

        await this.rtc.client.subscribe(user, mediaType);
        if (mediaType === "video") {
          const remoteVideoTrack = user.videoTrack;
          if (remoteVideoTrack) {
            remoteVideoTrack.play(document.getElementById("player-right"));
          }
          if(!this.isShowModalVideoCall)
            $("#myModalCall").modal("show");
        }

        if (mediaType === "audio") {
          const remoteAudioTrack = user.audioTrack;
          remoteAudioTrack.play();
        }
      });

      this.rtc.client.on("user-left", async (user, mediaType) => {
        if (mediaType == "Quit") {
          this.isShowModalVideoCall = false;

          const remotePlayerContainer = document.getElementById(user.uid);
          // Destroy the container.
          if (remotePlayerContainer) {
            remotePlayerContainer.remove();
          }
          $("#myModalCall").modal("hide");
          this.isShowModalVideoCall = false;
          await this.rtc.localVideoTrack.stop(
            document.getElementById("player-left")
          );
          await this.rtc.localVideoTrack.setEnabled(false);
          await this.rtc.localAudioTrack.setMuted(true);
          await this.rtc.localVideoTrack.close();
          await this.rtc.localAudioTrack.close();
          await this.rtc.client.leave();

          await this.rtc.client.join(
            this.options.appId,
            this.options.channel,
            this.options.token,
            this.options.uid
          );
        }
      });
    }

    //check if consultation is paid or not
    if(this.consultation && this.consultation.status == 'completed' && this.consultation.payment_status !== 'paid'){
      this.pendingPayment = true
      this.activeBtn = 'prescriptions'
    }

    //check if consultation is paid or not
    if (
      this.consultation.payment_status &&
      this.consultation.payment_status == "paid"
    )
      this.isPaid = true;
    else this.isPaid = false;

    this.isLoadingCircle = false;

    if(!this.pendingPayment) {
      this.chatScrollBottom();
    }
  },
  async beforeDestroy() {
    clearInterval(this.consultationIntervalStatus);
    if (this.rtc && this.rtc.client) await this.rtc.client.leave();
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none !important;
}

.footer {
  padding: 0px 20px 20px 20px;
}

.icon-user {
  background-color: #22262c;
  height: 70px;
  width: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-video {
  width: 100%;
  height: 370px;
  position: relative;
  display: block;
  background-color: #32363d;
  background-image: url(/img/empty-image.png);
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: center;
}

.custom-card {
  width: 600px;
  border-radius: 0.8rem;
}

.custom-container {
  height: calc(100vh - 142px);
  overflow: scroll;
}

.img-container {
  height: 60px;
  width: 60px;
  position: relative;
  display: block;
  background-color: #d9d9d9;
}

.custom-input:focus {
  box-shadow: none !important;
}

.custom-border {
  border-radius: 0.8rem !important;
}

.text-light-muted {
  font-size: 0.62rem;
  color: #d2d5da;
}

@media print {
  .custom-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-card {
    width: 100%;
  }

  .chat-container {
    width: 80vw;
  }

  .card-body.padding-0 {
    padding: 0px;
  }
}

.img-container img {
  height: auto;
  width: 100%;
}

.img-thumbnails {
  height: 35px;
  width: 35px;
  position: relative;
  display: block;
  background-color: #d9d9d9;
}

.img-thumbnails img {
  height: auto;
  width: 100%;
}

.bubble-right {
  margin-bottom: 25px;
  justify-content: flex-end;
}

.small-title {
  font-size: 0.8rem;
}

.bubble-right .bubble {
  background-color: #912018 !important;
  color: white !important;
  border-top-right-radius: 4px !important;
}

.bubble-left .bubble {
  margin-bottom: 25px;
  justify-content: flex-start;
  border-top-left-radius: 4px !important;
}

.nav-tabs-custom li {
  color: #667085 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer !important;
  padding-bottom: 5px;
}

.nav-tabs-custom .active {
  color: red !important;
  border-bottom: 1px solid red !important;
}

.nav-tabs-custom .active {
  color: red !important;
}

.nav-tabs-custom .active:hover {
  color: red !important;
  border-bottom: 1px solid red !important;
}

.nav-tabs-custom li:hover {
  color: #4e576b !important;
  border-bottom: 1px solid #4e576b !important;
}

.chat-container {
  height: calc(100vh - 400px);
  overflow: scroll;
}

.bg-submit {
  border-color: #f97066 !important;
  background-color: #f97066 !important;
  color: white !important;
}

.bg-attachment {
  background-color: #fef3f2 !important;
  color: #b42318 !important;
}

.fw-lighter {
  font-weight: lighter;
}

.fs-4 {
  font-size: 2rem;
}

.fs-6 {
  font-size: 0.9rem;
}

.logo {
  position: relative;
  display: block;
  height: 80px;
  width: 120px;
}

.logo img {
  height: auto;
  width: 100%;
}

.button-primary-color-bg {
  background-color: #f97066 !important;
  border-color: #f97066 !important;
}

@media (max-width: 720px) {
  .container-top-profile {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .box-video {
    height: calc(100vh / 2 - 130px);
  }
}

.icon-transform > i {
  transform: rotate(223deg);
}

.icon-transform.pickup > i {
  transform: rotate(320deg);
}

.dot {
  height: 14px;
  width: 14px;
  top: 0.8rem;
  left: 0.8rem;
}

.dot-sm {
  height: 10px;
  width: 10px;
  top: -0.1rem;
  left: -0.1rem;
  z-index: 2;
}
</style>